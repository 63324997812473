import { Col, Modal, Row, Typography } from "antd";
import React from "react";

const ViewModal = ({ modal2, setModal2, active }) => {
  return (
    <Modal open={modal2} onCancel={() => setModal2(false)} title="Movie/Series">
      <Row>
        <Col span={12}>
          <Typography.Title level={4}>Title</Typography.Title>
          <Typography.Text>{active?.name}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Title level={4}>Description</Typography.Title>
          <Typography.Text>
            {active?.description?.slice(0, 100)}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Title level={4}>Horizontal Image</Typography.Title>
          <img
            src={active?.horizontalBanner}
            alt={active?.horizontalBanner}
            style={{ width: 100, height: 100 }}
          />
        </Col>
        <Col span={12}>
          <Typography.Title level={4}>Vertical Image</Typography.Title>
          <img
            src={active?.verticalBanner}
            alt={active?.verticalBanner}
            style={{ width: 100, height: 100 }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewModal;
