import React from "react";
import useStore from "../utils/store";
import { Button, Form, Input, message, Modal } from "antd";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utils/api";

const ResetPassword = () => {
  const logout = useStore((state) => state.logout);
  const user = useStore((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const [form] = Form.useForm();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (d) => {
      const { data } = await axios.put(`${URL}/admin/password`, d);
      return data;
    },
    onSuccess: (data) => {
      form.resetFields();
      message.success("Password reset successfully");
      logout();
    },
  });
  return (
    <div
      style={{
        display: "flex",

        alignItems: "center",
        gap: "1rem",
      }}
    >
      <Modal
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
        title="Reset Password"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            if (values.password === values.confirmPassword) {
              mutate({
                password: values.password,
                id: user._id,
              });
              setOpen(false);
            } else {
              message.error("Passwords do not match");
            }
          }}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>{" "}
      <Button type="primary" onClick={() => setOpen(true)}>
        Reset Password
      </Button>
      <Button type="primary" onClick={logout}>
        Logout
      </Button>
    </div>
  );
};

export default ResetPassword;
