import { Button, Input, message, Modal, Table } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import {
  addCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from "../utils/api";

const Categories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, setModal] = React.useState(false);
  const [update, setUpdate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [buttonloading, setButtonLoading] = React.useState(false);
  const [newCategory, setNewCategory] = React.useState({});

  const getAllCategoriesData = async () => {
    setLoading(true);
    const [success, response] = await getAllCategories();
    if (success) setCategories(response);
    setLoading(false);
  };

  const deleteCategoryData = async (id) => {
    setLoading(true);
    const [success, response] = await deleteCategory(id);
    if (success) {
      messageApi.success("Category deleted successfully");
      getAllCategoriesData();
    }
    setLoading(false);
  };

  const createNewCategory = async () => {
    if (!newCategory.name || !newCategory.position)
      return messageApi.error("Please fill all the fields");
    setButtonLoading(true);
    if (!update) {
      const [success, response] = await addCategory(newCategory);
      if (success) {
        messageApi.success("Category added successfully");
        setModal(false);
        getAllCategoriesData();
      }
    } else {
      const [success, response] = await updateCategory(update, newCategory);
      if (success) {
        messageApi.success("Category updated successfully");
        setUpdate(null);
        setModal(false);
        getAllCategoriesData();
      }
    }
    setNewCategory({});
    setButtonLoading(false);
  };
  useValidate();
  useEffect(() => {
    getAllCategoriesData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title="Categories"
        button={"Add Category"}
        onClick={() => setModal(true)}
      />
      <Table
        scroll={{
          x: 500,
        }}
        dataSource={categories}
        loading={loading}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Position",
            dataIndex: "position",
            key: "position",
          },
          {
            title: "Sub Categories",
            dataIndex: "subcategories",
            key: "subcategories",
            render: (text, record) => (
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/subcategories/${record._id}/${record.name}`);
                }}
              >
                Sub-Categories
              </Button>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="row">
                <Button
                  type="primary"
                  onClick={() => {
                    setUpdate(record._id);
                    setNewCategory({
                      name: record.name,
                      position: record.position,
                    });
                    setModal(true);
                  }}
                >
                  Edit
                </Button>

                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this category?",
                      onOk: () => {
                        deleteCategoryData(record._id);
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
        ]}
      />
      <Modal
        open={modal}
        onCancel={() => setModal(false)}
        title={update ? "Update Category" : "Add New Category"}
        onOk={createNewCategory}
        confirmLoading={buttonloading}
      >
        <div
          className="col"
          style={{
            marginTop: 10,
          }}
        >
          <Input
            placeholder="Name"
            onChange={(e) =>
              setNewCategory((prev) => ({ ...prev, name: e.target.value }))
            }
            value={newCategory.name}
          />
          <Input
            placeholder="Position"
            onChange={(e) =>
              setNewCategory((prev) => ({ ...prev, position: e.target.value }))
            }
            value={newCategory.position}
          />
        </div>
      </Modal>
    </>
  );
};

export default Categories;
