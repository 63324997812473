import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, Input, message, Modal, Table, Upload } from "antd";
import React from "react";
import Head from "../components/common/Head";
import { isEnable } from "../components/common/IsEnabled";
import useValidate from "../hooks/useValidate";
import {
  addTopBanner,
  deleteTopBanner,
  getTopBanner,
  updateTopBanner,
} from "../utils/api";
const TopBanner = () => {
  const [modal, setModal] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [updateImage, setUpdateImage] = React.useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [loading2, setLoading2] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [topbanner, setTopBanner] = React.useState({});
  const getData = async () => {
    setLoading(true);
    const [success, response] = await getTopBanner();
    if (success) setData(response);
    setLoading(false);
  };

  const addBanner = async () => {
    if (!topbanner.position || !topbanner.link || !topbanner.image)
      return messageApi.error("Please fill all fields");

    setLoading2(true);
    const formData = new FormData();
    formData.append("position", topbanner.position);
    formData.append("image", topbanner.image);
    formData.append("link", topbanner.link);
    if (!update) {
      formData.append("isEnable", true);
      const [success, response] = await addTopBanner(formData);
      if (success) {
        messageApi.success("Banner Added");
        getData();
        setTopBanner({});
        setModal(false);
      }
    } else {
      if (updateImage !== topbanner.image) {
        formData.append("image", topbanner.image);
      }
      formData.append("isEnable", topbanner.isEnable);
      const [success, response] = await updateTopBanner(
        topbanner._id,
        formData
      );
      if (success) {
        messageApi.success(response.message);
        getData();
        setUpdate(false);
        setUpdateImage("");
        setTopBanner({});
        setModal(false);
      }
    }

    setLoading2(false);
  };

  useValidate();

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {contextHolder}
      <Head
        title="Top Banner"
        button="Add Top Banner"
        onClick={() => {
          setTopBanner({});
          setUpdate(false);
          setModal(true);
        }}
      />
      <Modal
        onOk={addBanner}
        confirmLoading={loading2}
        open={modal}
        onCancel={() => {
          setTopBanner({});
          setUpdate(false);
          setModal(false);
        }}
        title="Add Top Banner"
      >
        <div className="col2">
          <Input
            placeholder="Position"
            onChange={(e) => {
              setTopBanner({ ...topbanner, position: e.target.value });
            }}
            value={topbanner.position}
          />
          <Input
            placeholder="link"
            onChange={(e) => {
              setTopBanner({ ...topbanner, link: e.target.value });
            }}
            value={topbanner.link}
          />
          <Upload
            onChange={(e) => {
              setTopBanner({ ...topbanner, image: e.file.originFileObj });
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
      </Modal>
      <Table
        loading={loading}
        dataSource={data}
        columns={[
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="col2">
                <Button
                  type="primary"
                  onClick={async () => {
                    setUpdate(true);
                    setUpdateImage(record.image);
                    setTopBanner(record);
                    setModal(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={async () => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this banner?",
                      content: "This action cannot be undone",
                      onOk: async () => {
                        setLoading(true);
                        const [success, response] = await deleteTopBanner(
                          record._id
                        );

                        if (success) {
                          messageApi.success("Deleted");
                          getData();
                        }
                        setLoading(false);
                      },
                    });
                  }}
                >
                  Delete
                </Button>
                <Button
                  type="primary"
                  danger={record.isEnable ? true : false}
                  onClick={async () => {
                    setLoading(true);
                    const [success, response] = await updateTopBanner(
                      record._id,
                      {
                        isEnable: !record.isEnable,
                      }
                    );
                    if (success) {
                      messageApi.success(response);
                      getData();
                    }
                    setLoading(false);
                  }}
                >
                  {record.isEnable ? "Disable" : "Enable"}
                </Button>
              </div>
            ),
          },
          {
            title: "Position",
            dataIndex: "position",
            key: "position",
          },
          isEnable,
          {
            title: "Link",
            dataIndex: "link",
            key: "link",
            render: (text) => (
              <a href={text} target="_blank" rel="noreferrer">
                Link
              </a>
            ),
          },
          {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (text) => <Image src={text} width={100} height={100} />,
          },
        ]}
      />
    </>
  );
};

export default TopBanner;
