import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Card, Form, Input, message, Switch } from "antd";
import React, { useEffect } from "react";
import { getSystemValue, updateSystemValues2 } from "../utils/api";

const SystemValues = () => {
  const [systemValues, setSystemValues] = React.useState({});
  const [form] = Form.useForm();
  const { data, isLoading } = useQuery({
    queryKey: ["systemvalues"],
    queryFn: getSystemValue,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateSystemValues2,
    onSuccess: (data) => {
      message.success("Updated successfully");
    },
  });

  const isChecked = Form.useWatch("isPhonePeEnabled", form);

  useEffect(() => {
    setSystemValues(data);
    form.setFieldsValue(data);
  }, [data, form]);
  return (
    <div>
      <Card
        title="Payment Gateway Details"
        style={{
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        {" "}
        <Form
          title=" Payment Gateway Details"
          form={form}
          layout="vertical"
          onFinish={(values) => {
            console.log(values);

            setSystemValues(values);
            mutate(values);
          }}
          initialValues={systemValues}
        >
          <Form.Item label="Is PhonePe Enabled" name="isPhonePeEnabled">
            <Switch checked={isChecked} />
          </Form.Item>

          <Form.Item label="PhonePe Salt " name="phonePeSalt">
            <Input />
          </Form.Item>
          <Form.Item label="PhonePe Merchant Key " name="phonePeMerchantKey">
            <Input />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            loading={isPending || isLoading}
            style={{ width: "100%" }}
          >
            Update
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default SystemValues;
