import {
  ApartmentOutlined,
  ClusterOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import React from "react";
import useValidate from "../hooks/useValidate";
import { getDashboard } from "../utils/api";

const Dashboard = () => {
  const [data, setData] = React.useState([]);
  const getDashboardData = async () => {
    const [success, response] = await getDashboard();
    if (success) setData(response);
  };
  useValidate();
  React.useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <>
      <Typography.Title level={1} style={{ margin: 0 }}>
        Dashboard
      </Typography.Title>
      {/* dashboard colorfull card for data  diff bg color  */}
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={8}>
          <Card>
            <Statistic
              title="Total Users"
              value={data.users}
              //title color

              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <Statistic
              title="Total Movies"
              value={data.movies}
              prefix={<VideoCameraOutlined />}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <Statistic
              title="Total Series"
              value={data.series}
              prefix={<ClusterOutlined />}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <Statistic
              title="Total Categories"
              value={data.categories}
              prefix={<ApartmentOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
