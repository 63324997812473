import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Row, Select, Typography, Upload } from "antd";
import React, { useEffect } from "react";
import { createNewBanner, updateBanner } from "../utils/api";

const BannerModal = ({
  reload,
  categories,
  movies,
  series,
  messageApi,

  update,
  modal,
  setModal,
}) => {
  const [type, setType] = React.useState(undefined);

  const [buttonloading, setButtonLoading] = React.useState(false);
  const [newBanner, setNewBanner] = React.useState({});

  const createBanner = async () => {
    const formData = new FormData();
    formData.append("position", newBanner.position);
    formData.append("origin", newBanner.origin);
    formData.append("categoryId", newBanner.categoryId);
    if (newBanner.origin === "Video") {
      formData.append("url", newBanner.url);
    } else {
      formData.append("id", newBanner.id);
      formData.append("type", type);
    }
    if (!update) {
      if (newBanner.position === "" || newBanner.origin === "") {
        messageApi.error("Please fill all the fields");
        return;
      }
      setButtonLoading(true);
      if (newBanner.origin === "Image") {
        formData.append("image", newBanner.image);
      }
      formData.append("isEnable", true);

      const [success, response] = await createNewBanner(formData);
      if (success) {
        messageApi.success("Banner created successfully");
        setModal(false);
        reload();
      } else {
        messageApi.error(response.message);
      }
    } else {
      if (newBanner.image !== update.image) {
        formData.append("image", newBanner.image);
      }
      formData.append("isEnable", update.isEnable);
      const [success, response] = await updateBanner(update._id, formData);
      if (success) {
        messageApi.success("Banner updated successfully");
        setModal(false);
        reload();
      } else {
        messageApi.error(response.message);
      }
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (update) {
      setNewBanner({
        id: update?.link?.item?._id,
        position: update?.position,
        categoryId: update?.category,
        image: update?.image,
        origin: update?.origin,
        isEnable: update?.isEnable,
        url: update?.url,
      });
      if (update?.link !== undefined) {
        setType(update?.link?.kind);
      }
    }
  }, [update]);

  return (
    <>
      <Modal
        confirmLoading={buttonloading}
        title={update ? "Update Banner" : "Create New Banner"}
        open={modal}
        onCancel={() => setModal(false)}
        onOk={createBanner}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Select
            placeholder="Origin"
            onChange={(e) =>
              setNewBanner((prev) => ({
                ...prev,
                origin: e,
              }))
            }
            options={[
              {
                value: "Video",
                label: "Video",
              },
              {
                value: "Image",
                label: "Image",
              },
            ]}
            value={newBanner.origin}
          />
          <Input
            placeholder="Position"
            onChange={(e) =>
              setNewBanner((prev) => ({
                ...prev,
                position: e.target.value,
              }))
            }
            value={newBanner.position}
          />
          <Select
            placeholder="Select Category"
            onChange={(e) =>
              setNewBanner((prev) => ({
                ...prev,
                categoryId: e,
              }))
            }
            options={categories.map((item, index) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
            value={newBanner.categoryId}
          />
          {newBanner.origin === "Image" ? (
            <>
              <Select
                placeholder="Select Type"
                onChange={(e) => setType(e)}
                options={[
                  {
                    value: "movie",
                    label: "Movies",
                  },
                  {
                    value: "series",
                    label: "Series",
                  },
                ]}
                value={type}
              />
              {type !== undefined && (
                <Select
                  placeholder={`Select ${type}`}
                  onChange={(e) =>
                    setNewBanner((prev) => ({
                      ...prev,
                      id: e,
                    }))
                  }
                  options={
                    type === "movie"
                      ? movies.map((item, index) => {
                          return {
                            value: item._id,
                            label: item.name,
                          };
                        })
                      : series.map((item, index) => {
                          return {
                            value: item._id,
                            label: item.name,
                          };
                        })
                  }
                  value={newBanner.id}
                />
              )}
              <Row justify={"space-between"}>
                <Typography.Text>Image</Typography.Text>
                <Upload
                  onChange={(e) => {
                    setNewBanner((prev) => ({
                      ...prev,
                      image: e.file.originFileObj,
                    }));
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Row>
              {update && (
                <img
                  src={newBanner.image}
                  alt="Banner"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              )}{" "}
            </>
          ) : (
            newBanner.origin === "Video" && (
              <>
                <Input
                  placeholder="Video Link"
                  onChange={(e) =>
                    setNewBanner((prev) => ({
                      ...prev,
                      url: e.target.value,
                    }))
                  }
                  value={newBanner.url}
                />
              </>
            )
          )}
        </div>
      </Modal>
    </>
  );
};

export default BannerModal;
