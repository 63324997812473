import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { addUpload } from "../../utils/api";

const UploadData = ({ setNewFile, files, type }) => {
  const inputFile = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const uploadHandler = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const [success, response] = await addUpload(formData);
    console.log(response);
    if (success) {
      setFile(null);
      setNewFile({
        ...files,
        [type ?? "url"]: response?.[0],
      });
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
    }
    setIsLoading(false);
  };
  return (
    <div>
      <h1 style={{ color: "#fff", fontSize: "14px", fontWeight: "normal" }}>
        Upload {type ? type?.toUpperCase() : "URL"}
      </h1>
      {files?.[type ?? "url"] && (
        <div
          style={{
            border: "1px solid #3e3e3e",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#141414",
          }}
        >
          <Link
            to={files?.[type ?? "url"]}
            target="_blank"
            style={{ color: "#fff" }}
          >
            {" "}
            {files?.[type ?? "url"]}
          </Link>
        </div>
      )}
      <div
        style={{
          marginTop: "20px",
          border: "1px solid #3e3e3e",
          borderRadius: "10px",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#141414",
        }}
      >
        <input
          ref={inputFile}
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <Button
          loading={isLoading}
          onClick={() => {
            uploadHandler();
          }}
        >
          Upload
        </Button>
      </div>{" "}
    </div>
  );
};

export default UploadData;
