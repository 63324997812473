import { Button, message, Modal, Table, Typography } from "antd";
import React from "react";
import BannerModal from "../banners/Modal";
import ViewModal from "../banners/ViewModal";
import Head from "../components/common/Head";
import { isEnable } from "../components/common/IsEnabled";
import useValidate from "../hooks/useValidate";
import {
  deleteBanner,
  getAllCategories,
  getAllMovies,
  getAllSeries,
  getBanners,
  updateBanner,
} from "../utils/api";

const Banners = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [movies, setMovies] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [update, setUpdate] = React.useState({});
  const [modal2, setModal2] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [active, setActive] = React.useState({});

  const getAllMoviesdata = async () => {
    setLoading(true);
    const [status, response] = await getAllMovies();
    if (status) setMovies(response);
    setLoading(false);
  };

  const getAllSeriesData = async () => {
    setLoading(true);
    const [status, response] = await getAllSeries();
    if (status) setSeries(response);
    setLoading(false);
  };

  const getAllBannerData = async () => {
    setLoading(true);
    const [success, response] = await getBanners();

    if (success) setData(response);
    setLoading(false);
  };

  const getAllCategoriesData = async () => {
    setLoading(true);
    const [success, response] = await getAllCategories();

    if (success) setCategories(response);
    setLoading(false);
  };

  const deleteBan = async (id) => {
    setLoading(true);
    const [success, response] = await deleteBanner(id);
    if (success) {
      messageApi.success("Banner deleted successfully");
      getAllBannerData();
    } else {
      messageApi.error(response.message);
    }
    setLoading(false);
  };
  useValidate();
  React.useEffect(() => {
    getAllBannerData();
    getAllMoviesdata();
    getAllSeriesData();
    getAllCategoriesData();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) =>
        record.origin === "Image" ? (
          <img
            src={record.image}
            alt={record.image}
            style={{ width: 100, height: 100 }}
          />
        ) : (
          <video width="200" height="120" controls controlsList="nodownload">
            <source src={record.url} type="video/mp4" />
          </video>
        ),
    },
    isEnable,
    {
      title: "Category Name",
      dataIndex: "category",
      key: "category",
      render: (text, record) => (
        <Typography.Text>
          {categories.find((item) => item._id === record.category)?.name}
        </Typography.Text>
      ),
    },
    {
      title: "type",
      dataIndex: "link",
      key: "type",
      render: (text, record) => (
        <Typography.Text>
          {" "}
          {record.link !== undefined ? record.link.kind : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Origin",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Movie/Series",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) =>
        record.link !== undefined ? (
          <Button
            type="primary"
            onClick={() => {
              setActive(record.link.item);
              setModal2(true);
            }}
          >
            View
          </Button>
        ) : (
          <></>
        ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => (
        <div style={{ display: "flex", gap: ".5rem", flexDirection: "column" }}>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure?",
                content: "This will delete the banner",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                  deleteBan(record._id);
                },
                onCancel() {},
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            danger={record.isEnable}
            onClick={async () => {
              setLoading(true);
              const [success, response] = await updateBanner(record._id, {
                isEnable: !record.isEnable,
              });
              if (success) {
                messageApi.success("Banner updated successfully");
                getAllBannerData();
              } else {
                messageApi.error(response.message);
              }
              setLoading(false);
            }}
          >
            {record.isEnable ? "Disable" : "Enable"}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setUpdate(record);
              setModal3(true);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <BannerModal
        series={series}
        movies={movies}
        categories={categories}
        messageApi={messageApi}
        reload={getAllBannerData}
        modal={modal}
        setModal={setModal}
      />
      <BannerModal
        series={series}
        movies={movies}
        categories={categories}
        messageApi={messageApi}
        reload={getAllBannerData}
        update={update}
        modal={modal3}
        setModal={setModal3}
      />
      <Head
        title="Banners"
        button="Add Banner"
        onClick={() => {
          setModal(true);
        }}
      />
      <Table
        scroll={{ x: 500 }}
        dataSource={data}
        columns={columns}
        loading={loading}
      />
      <ViewModal active={active} modal2={modal2} setModal2={setModal2} />
    </>
  );
};

export default Banners;
