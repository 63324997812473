import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Switch,
  Typography,
  Upload,
} from "antd";
import React from "react";
import {
  addEpisode,
  addMovie,
  addSeries,
  updateEpisode,
  updateMovie,
  updateSeries,
} from "../../utils/api";
import CustomInput, { CustomTextArea } from "../Input";
import CustomSelect from "../Select";
import UploadData from "./Upload";

const MoviesModal = ({
  modal,
  setModal,
  series,
  messageApi,
  reload,
  genres,
  update,
  episodes,
  id,
}) => {
  const [buttonloading, setButtonLoading] = React.useState(false);
  const [newMovie, setNewMovie] = React.useState({});
  const [newGenre, setNewGenre] = React.useState([]);
  const { TextArea } = Input;
  const [casts, setCasts] = React.useState([]);
  console.log(newMovie);
  const addNewMovie = async () => {
    if (
      !newMovie.name ||
      !newMovie.description ||
      (!series && !newMovie.url) ||
      !newMovie.horizontalBanner ||
      !newMovie.verticalBanner ||
      (!episodes && !newMovie.duration) ||
      (!episodes && !newMovie.rating)
    ) {
      messageApi.error("Please fill all the fields");
      return;
    }
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("name", newMovie.name);
    formData.append("description", newMovie.description);
    if (!series) {
      formData.append("url", newMovie.url);
      formData.append("urlType", newMovie.urlType);
      formData.append("duration", newMovie.duration);
    }

    if (!episodes) {
      formData.append("trailer", newMovie.trailer);
      formData.append("genres", JSON.stringify(newGenre));
      formData.append("cast", JSON.stringify(casts));
      formData.append("language", newMovie.language);
      formData.append("ageLimit", newMovie.ageLimit);

      formData.append("rating", newMovie.rating);
      formData.append("releaseYear", newMovie.releaseYear);
      formData.append("trailerType", newMovie.trailerType);
      formData.append("isEnable", true);
      formData.append("isFree", newMovie?.isFree ?? false);
    } else {
      formData.append("seriesId", id);
      formData.append("episodeNumber", newMovie.episodeNumber);
      formData.append("rating", "");

      formData.append("language", "");
      formData.append("ageLimit", "");
      formData.append("genres", JSON.stringify([]));
      formData.append("cast", JSON.stringify([]));
      formData.append("trailer", "");
    }

    if (!update) {
      formData.append("horizontalBanner", newMovie.horizontalBanner);
      formData.append("verticalBanner", newMovie.verticalBanner);

      const [success, response] = series
        ? await addSeries(formData)
        : episodes
        ? await addEpisode(formData)
        : await addMovie(formData);
      if (success) {
        messageApi.success(
          `${
            series ? "Series" : episodes ? "Episode" : "Movie"
          } created successfully`
        );
        setModal(false);
        setNewMovie({});
        setNewGenre([]);
        setCasts([]);

        reload();
      } else {
        messageApi.error(response.message);
      }
    } else {
      if (newMovie.horizontalBanner !== update.horizontalBanner) {
        formData.append("horizontalBanner", newMovie.horizontalBanner);
      }
      if (newMovie.verticalBanner !== update.verticalBanner) {
        formData.append("verticalBanner", newMovie.verticalBanner);
      }
      if (episodes) {
        formData.append("episodeId", update._id);
      }

      const [success, response] = series
        ? await updateSeries(update._id, formData)
        : episodes
        ? await updateEpisode(formData)
        : await updateMovie(update._id, formData);

      if (success) {
        messageApi.success(
          `${
            series ? "Series" : episodes ? "Episode" : "Movie"
          } updated successfully`
        );
        setModal(false);
        setNewMovie({});
        setNewGenre([]);
        setCasts([]);

        reload();
      } else {
        messageApi.error(response.message);
      }
    }
    setButtonLoading(false);
  };

  React.useEffect(() => {
    if (update) {
      setNewMovie({
        name: update.name,
        description: update.description,
        url: update.url,
        horizontalBanner: update.horizontalBanner,
        verticalBanner: update.verticalBanner,
        duration: update.duration,
        rating: update.rating,
        language: update.language,
        ageLimit: update.ageLimit,
        trailer: update.trailer,
        releaseYear: update.releaseYear,
        urlType: update.urlType,
        trailerType: update.trailerType,
        isFree: update.isFree,
      });
      setNewGenre(
        update?.genres?.map((genre) => {
          return genre._id;
        })
      );
      setCasts(update.cast);
    }
  }, [update]);

  return (
    <Modal
      open={modal}
      onCancel={() => setModal(false)}
      title={
        update
          ? `Update ${series ? "Series" : episodes ? "Episode" : "Movie"}`
          : `Add ${series ? "Series" : episodes ? "Episode" : "Movie"}`
      }
      onOk={addNewMovie}
      width={400}
      confirmLoading={buttonloading}
    >
      <div className="col">
        <CustomInput
          placeholder="Name"
          onChange={(e) => setNewMovie({ ...newMovie, name: e.target.value })}
          value={newMovie.name}
        />
        <CustomTextArea
          placeholder="Description"
          onChange={(e) =>
            setNewMovie({ ...newMovie, description: e.target.value })
          }
          value={newMovie.description}
        />
        {!series && (
          <>
            <CustomSelect
              placeholder="Select URL Type"
              onChange={(e) => setNewMovie({ ...newMovie, urlType: e })}
              options={[
                { label: "Video", value: "Video" },
                { label: "Youtube", value: "Youtube" },
              ]}
              value={newMovie.urlType}
            />
            {/* <CustomInput
              placeholder="Url"
              onChange={(e) =>
                setNewMovie({ ...newMovie, url: e.target.value })
              }
              value={newMovie.url}
            />{" "} */}
            <UploadData setNewFile={setNewMovie} files={newMovie} />
            <CustomInput
              placeholder="Duration"
              onChange={(e) =>
                setNewMovie({ ...newMovie, duration: e.target.value })
              }
              value={newMovie.duration}
            />
          </>
        )}

        {!episodes && (
          <>
            <CustomSelect
              placeholder="Select Trailer Type"
              onChange={(e) => setNewMovie({ ...newMovie, trailerType: e })}
              options={[
                { label: "Video", value: "Video" },
                { label: "Youtube", value: "Youtube" },
              ]}
              value={newMovie.trailerType}
            />

            {/* <CustomInput
              placeholder="Trailer"
              onChange={(e) =>
                setNewMovie({ ...newMovie, trailer: e.target.value })
              }
              value={newMovie.trailer}
            /> */}
            <UploadData
              setNewFile={setNewMovie}
              files={newMovie}
              type={"trailer"}
            />
            <Typography style={{ marginRight: 10 }}>IsFree</Typography>
            <Switch
              placeholder="isFree"
              onChange={(e) => setNewMovie({ ...newMovie, isFree: e })}
              checked={newMovie.isFree}
            />

            <CustomSelect
              placeholder="Select Language"
              onChange={(e) => setNewMovie({ ...newMovie, language: e })}
              options={[
                { label: "Hindi", value: "Hindi" },
                { label: "English", value: "English" },
                { label: "Marathi", value: "Marathi" },
                { label: "Punjabi", value: "Punjabi" },
                { label: "Gujarati", value: "Gujarati" },
                { label: "Bengali", value: "Bengali" },
                { label: "Bhojpuri", value: "Bhojpuri" },
                { label: "Urdu", value: "Urdu" },
                { label: "Telugu", value: "Telugu" },
                { label: "Tamil", value: "Tamil" },
                { label: "Kannada", value: "Kannada" },
                { label: "Malayalam", value: "Malayalam" },
                { label: "Tulu", value: "Tulu" },
                { label: "Assamese", value: "Assamese" },
              ]}
              value={newMovie.language}
            />
            <CustomSelect
              placeholder="Rating"
              onChange={(e) => setNewMovie({ ...newMovie, rating: e })}
              value={newMovie.rating}
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
              ]}
            />
            <CustomSelect
              placeholder="Age Limit"
              onChange={(e) => setNewMovie({ ...newMovie, ageLimit: e })}
              value={newMovie.ageLimit}
              options={[
                { label: "13+", value: "13+" },
                { label: "16+", value: "16+" },
                { label: "18+", value: "18+" },
                { label: "U", value: "U" },
                { label: "U/A", value: "U/A" },
                { label: "A", value: "A" },
              ]}
            />
            <CustomInput
              placeholder="Release Year"
              onChange={(e) =>
                setNewMovie({ ...newMovie, releaseYear: e.target.value })
              }
              value={newMovie.releaseYear}
            />

            <Typography style={{ marginRight: 10 }}>Genres</Typography>
            <div className="genres">
              {genres?.map((item) => (
                <Checkbox
                  key={item._id}
                  checked={newGenre?.includes(item._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNewGenre([...newGenre, item._id]);
                    } else {
                      setNewGenre(newGenre?.filter((i) => i !== item._id));
                    }
                  }}
                >
                  {item?.name}
                </Checkbox>
              ))}
            </div>
          </>
        )}

        {episodes && !update && (
          <CustomInput
            placeholder="Episode-Number"
            onChange={(e) =>
              setNewMovie({ ...newMovie, episodeNumber: e.target.value })
            }
            value={newMovie.episodeNumber}
          />
        )}

        <Upload
          multiple={false}
          onRemove={(e) => {
            setNewMovie({ ...newMovie, horizontalBanner: "" });
          }}
          beforeUpload={(file) => {
            setNewMovie({ ...newMovie, horizontalBanner: file });
            return false;
          }}
          fileList={
            newMovie.horizontalBanner ? [newMovie.horizontalBanner] : []
          }
        >
          <Button icon={<UploadOutlined />}>Upload Horizonal Banner</Button>
        </Upload>
        <Upload
          multiple={false}
          onRemove={(e) => {
            setNewMovie({ ...newMovie, verticalBanner: "" });
          }}
          beforeUpload={(file) => {
            setNewMovie({ ...newMovie, verticalBanner: file });
            return false;
          }}
          fileList={newMovie.verticalBanner ? [newMovie.verticalBanner] : []}
        >
          <Button icon={<UploadOutlined />}>Upload Vertical Banner</Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default MoviesModal;
