import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, message, Modal, Table, Upload } from "antd";
import React, { useEffect } from "react";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import {
  addShortBanner,
  deleteShortBanner,
  getShortBanner,
} from "../utils/api";
const ShortBanner = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [image, setImage] = React.useState({});
  const getData = async () => {
    setLoading(true);
    const [success, response] = await getShortBanner();
    if (success) setData(response);
    setLoading(false);
  };
  useValidate();
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {contextHolder}
      <Head
        title="Short Banner"
        button={"Add Short Banner"}
        onClick={() => setModal(true)}
        disabled={data.image ? true : false}
      />
      <Table
        loading={loading}
        dataSource={data.image ? [data] : []}
        columns={[
          {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (text, record) => <Image src={data.image} width={100} />,
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <Button
                danger
                type="primary"
                onClick={async () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this banner?",
                    content: "This action cannot be undone",
                    onOk: async () => {
                      setLoading(true);
                      const [success, response] = await deleteShortBanner(
                        record._id
                      );
                      if (success) {
                        messageApi.success("Deleted");
                        getData();
                      }
                      setLoading(false);
                    },
                  });
                }}
              >
                Delete
              </Button>
            ),
          },
        ]}
      />
      <Modal
        title="Add Short Banner"
        open={modal}
        confirmLoading={loading2}
        onCancel={() => setModal(false)}
        onOk={async () => {
          if (!image.image) return messageApi.error("Please select an image");
          setLoading2(true);
          const formData = new FormData();
          formData.append("image", image.image);
          const [success, response] = await addShortBanner(formData);
          if (success) {
            messageApi.success("Added");
            setModal(false);
            getData();
          }
          setLoading2(false);
        }}
      >
        <Upload
          onChange={(e) => {
            setImage({ image: e.file.originFileObj });
          }}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Modal>
    </>
  );
};

export default ShortBanner;
