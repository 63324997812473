import { message } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import Head from "../components/common/Head";
import MoviesModal from "../components/movies/Modal";
import ContentTable from "../components/movies/Table";
import useValidate from "../hooks/useValidate";
import { getSeriesById } from "../utils/api";

const Episodes = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [series, setSeries] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [update, setUpdate] = React.useState({});
  const { id } = useParams();
  const getAllSeriesData = async () => {
    setLoading(true);
    const [success, response] = await getSeriesById(id);
    if (success) setSeries(response);
    setLoading(false);
  };
  useValidate();

  React.useEffect(() => {
    getAllSeriesData();
  }, []);

  return (
    <>
      {contextHolder}
      <Head
        title={series.name}
        button={"Add Episode"}
        onClick={() => setModal(true)}
      />
      <ContentTable
        data={series.episodes}
        loading={loading}
        setModal2={setModal2}
        setUpdate={setUpdate}
        messageApi={messageApi}
        reload={getAllSeriesData}
        episodes
        id={id}
      />
      <MoviesModal
        modal={modal}
        setModal={setModal}
        messageApi={messageApi}
        reload={getAllSeriesData}
        episodes
        id={id}
      />
      <MoviesModal
        modal={modal2}
        setModal={setModal2}
        messageApi={messageApi}
        reload={getAllSeriesData}
        update={update}
        episodes
        id={id}
      />
    </>
  );
};

export default Episodes;
