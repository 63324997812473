import {
  BellOutlined,
  ClusterOutlined,
  ContainerOutlined,
  CreditCardTwoTone,
  DatabaseOutlined,
  PicRightOutlined,
  PictureOutlined,
  PieChartOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Image, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.jpg";
import useStore from "../../utils/store";
import ResetPassword from "../ResetPassword";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}
const items = [
  getItem("Dashboard", "1", <PieChartOutlined />, null, "/"),
  getItem(
    "Payment Gateway",
    "20",
    <CreditCardTwoTone />,
    null,
    "/system-values"
  ),
  getItem("Banner", "2", <PictureOutlined />, null, "/banners"),
  getItem("Category", "3", <ClusterOutlined />, null, "/categories"),
  getItem("Genres", "4", <ContainerOutlined />, null, "/genres"),
  getItem("Movies", "5", <VideoCameraOutlined />, null, "/movies"),
  getItem("Series", "6", <DatabaseOutlined />, null, "/series"),
  getItem("Users", "7", <UserOutlined />, null, "/users"),
  getItem("Subscription", "11", <UserOutlined />, null, "/subscription"),
  getItem("Notification", "8", <BellOutlined />, null, "/notification"),
  getItem("Top Banner", "9", <PicRightOutlined />, null, "/topbanner"),
  getItem("Short Banner", "10", <PicRightOutlined />, null, "/shortBanner"),
];
const Layouts = ({ children }) => {
  const logout = useStore((state) => state.logout);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth="0"
        style={{
          background: "black",
        }}
      >
        <div
          style={{
            padding: 16,
          }}
        >
          <Image
            src={logo}
            width={80}
            style={{
              borderRadius: 8,
            }}
            preview={false}
          />
        </div>
        <Menu
          style={{
            background: "black",
          }}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={
            items && items.find((item) => item.path === location.pathname)?.key
          }
        >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "black",
          }}
        >
          {/* dark nav bar logout*/}

          <div
            style={{
              display: "flex",
              justifyContent: collapsed ? "space-between" : "flex-end",
              alignItems: "center",
              gap: 16,
              padding: collapsed ? "0rem 1rem" : "1rem",
            }}
          >
            {collapsed && (
              <Image
                src={logo}
                width={70}
                style={{
                  borderRadius: 8,
                }}
                preview={false}
              />
            )}
            <ResetPassword />
          </div>
        </Header>
        <Content style={{}}>
          {/* <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            style={{
              minHeight: "90dvh",
              background: colorBgContainer,
              padding: 24,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          GILMA ©2024
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;
