import { Input as I, Typography } from "antd";
import React from "react";
const CustomInput = ({ onChange, value, name, type, placeholder }) => {
  return (
    <fieldset
      style={{
        border: "none",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: 2,
      }}
    >
      <label htmlFor={name}>
        <Typography
          style={{
            fontWeight: 600,
          }}
        >
          {name ?? placeholder}
        </Typography>
      </label>
      <I
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        type={type ?? "text"}
        placeholder={placeholder}
      />
    </fieldset>
  );
};

export default CustomInput;

export const CustomTextArea = ({
  onChange,
  value,
  name,
  type,
  placeholder,
}) => {
  return (
    <fieldset
      style={{
        border: "none",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: 2,
      }}
    >
      <label htmlFor={name}>
        <Typography
          style={{
            fontWeight: 600,
          }}
        >
          {name ?? placeholder}
        </Typography>
      </label>
      <I.TextArea
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        type={type ?? "text"}
        placeholder={placeholder}
      />
    </fieldset>
  );
};
