import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const useValidate = () => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  React.useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }, [user, navigate]);
  return [navigate, user];
};

export default useValidate;
