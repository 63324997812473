import { Select, Typography } from "antd";
import React from "react";
const CustomSelect = ({
  onChange,
  value,
  name,
  type,
  placeholder,
  options,
  mode,
  style,
}) => {
  return (
    <fieldset
      style={{
        border: "none",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: 2,
      }}
    >
      <label htmlFor={name}>
        <Typography
          style={{
            fontWeight: 600,
          }}
        >
          {name}
        </Typography>
      </label>
      <Select
        id={name}
        onChange={onChange}
        value={value}
        options={options}
        placeholder={placeholder}
        mode={mode}
        style={style}
      />
    </fieldset>
  );
};

export default CustomSelect;
