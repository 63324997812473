import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Input, message, Select, Upload } from "antd";
import React from "react";
import useValidate from "../hooks/useValidate";
import { addUserNotification, getAllMovies, getAllSeries } from "../utils/api";

const Notification = () => {
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [notification, setNotification] = React.useState({
    type: "movie",
  });
  const [movies, setMovies] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const addUserNotifications = async () => {
    if (
      !notification?.title ||
      !notification?.description ||
      !notification?.image ||
      !notification?.type ||
      !notification?.id
    )
      return messageApi.error("Please fill all the fields");
    setLoading(true);
    const formData = new FormData();
    formData.append("title", notification.title);
    formData.append("description", notification.description);
    formData.append("image", notification.image);
    formData.append("type", notification.type);
    formData.append("id", notification.id);

    const [success, response] = await addUserNotification(formData);

    if (success) messageApi.success("Notification added successfully");
    setLoading(false);
  };

  const getData = async () => {
    const [success, response] = await getAllMovies();

    if (success) setMovies(response);

    const [success2, response2] = await getAllSeries();

    if (success2) setSeries(response2);
  };

  useValidate();

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {contextHolder}
      <Card title="Notification" className="notification_card">
        <div className="notification">
          <Input
            placeholder="Title"
            onChange={(e) => {
              setNotification({ ...notification, title: e.target.value });
            }}
          />
          <Input
            placeholder="Description"
            onChange={(e) => {
              setNotification({ ...notification, description: e.target.value });
            }}
          />
          <Select
            options={[
              { label: "Movie", value: "movie" },
              { label: "Series", value: "series" },
            ]}
            placeholder="Type"
            onChange={(e) => {
              setNotification({ ...notification, type: e });
            }}
            defaultValue={notification.type}
          />
          <Select
            options={
              notification.type === "movie"
                ? movies.map((movie) => {
                    return {
                      label: movie.name,
                      value: movie._id,
                    };
                  })
                : series.map((serie) => {
                    return {
                      label: serie.title,
                      value: serie._id,
                    };
                  })
            }
            placeholder="Select Movie/Series"
            onChange={(e) => {
              setNotification({ ...notification, id: e });
            }}
          />
          <Upload
            onChange={(e) => {
              setNotification({ ...notification, image: e.file.originFileObj });
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>
        <Button
          onClick={addUserNotifications}
          loading={loading}
          type="primary"
          style={{ marginTop: "20px" }}
        >
          Send
        </Button>
      </Card>
    </>
  );
};

export default Notification;
