import { Button, Input, Modal, Table } from "antd";
import React from "react";

import {
  changeMoviePosition,
  changeSeriesPosition,
  removeMovieFromSubCategory,
  removeSeriesFromSubCategory,
} from "../../utils/api";
const { Search } = Input;
const ContentModal = ({
  modal2,
  setModal2,
  content,
  id,
  messageApi,
  reload,
}) => {
  const [newPosition, setNewPosition] = React.useState("");
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const removeDataFromSubCategory = async (type, id2) => {
    setButtonLoading(true);
    if (type === "movie") {
      const [status, response] = await removeMovieFromSubCategory({
        subcategoryId: id,
        movieId: id2,
      });
      if (status) {
        messageApi.success("Movie Removed Successfully");
      }
    }
    if (type === "series") {
      const [status, response] = await removeSeriesFromSubCategory({
        subcategoryId: id,
        seriesId: id2,
      });
      if (status) {
        messageApi.success("Series Removed Successfully");
      }
    }
    setModal2(false);
    setButtonLoading(false);
    reload();
  };

  const changeposition = async (type, id2) => {
    setButtonLoading(true);
    if (newPosition === "") return messageApi.error("Please enter a position");
    if (type === "movie") {
      const [status, response] = await changeMoviePosition({
        subcategoryId: id,
        movieId: id2,
        position: newPosition,
      });
      if (status) {
        messageApi.success("Position changed successfully");
      }
    }
    if (type === "series") {
      const [status, response] = await changeSeriesPosition({
        subcategoryId: id,
        seriesId: id2,
        position: newPosition,
      });
      if (status) {
        messageApi.success("Position changed successfully");
      }
    }
    setModal2(false);
    setButtonLoading(false);
    reload();
  };

  return (
    <Modal
      title="Content"
      open={modal2}
      width={600}
      footer={null}
      onCancel={() => setModal2(false)}
    >
      <Table
        dataSource={
          content.map((item) => ({
            ...item.item,
            type: item.kind,
          })) || []
        }
        columns={[
          { title: "name", dataIndex: "name", key: "name" },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="col2">
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure you want to remove this content?",
                      onOk: () => {
                        removeDataFromSubCategory(record.type, record._id);
                      },
                    });
                  }}
                >
                  Remove
                </Button>

                <Search
                  enterButton="Edit"
                  placeholder="Enter new position"
                  onChange={(e) => setNewPosition(e.target.value)}
                  onSearch={() => changeposition(record.type, record._id)}
                  loading={buttonLoading}
                />
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default ContentModal;
